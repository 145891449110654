// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    
    MuiAutocomplete: { // Target the Autocomplete component
      styleOverrides: {
        root: {
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 1000,
          "& .MuiAutocomplete-inputRoot": {
            backgroundColor: "white",
            //backdropFilter: "blur(6px)",
            borderRadius: 8,
            //boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          },
          "& .MuiAutocomplete-popper": {
           // backdropFilter: "blur(4px)",
            backgroundColor: "rgba(255, 255, 255, 0)",
            //borderRadius: 8,
            //boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          },
          "& .MuiAutocomplete-option": {
            backgroundColor: "rgba(255, 255, 255, 0)",
            //borderRadius: 4,
            //backdropFilter: "blur(4px)",
          },
          "& .MuiAutocomplete-groupUl": {
            backgroundColor: "rgba(255, 255, 255, 0)",
            //borderRadius: 4,
            //backdropFilter: "blur(4px)",
          },
          "& .MuiAutocomplete-groupLabel": {
            backgroundColor: "rgba(255, 255, 255, 0)",
            //borderRadius: 4,
            //backdropFilter: "blur(4px)",
          }
        },
      },
    },
    /*MuiPopup: { // Target the Leaflet popup component
      styleOverrides: {
        contentWrapper: {
          height: "fit-content !important",
          boxShadow: "none !important",
          marginBottom: "-10px !important",
          width: "100% !important",
          backgroundColor: "rgba(241, 240, 240, 0)",
        },
        content: {
          width: "100% !important",
          height: "100% !important",
          boxShadow: "none !important",
          margin: "0 !important",
        },
        tipContainer: {
          background: "rgba(130, 230, 0, 1) !important",
          boxShadow: "none !important",
        },
        close: {
          display: "none",
        },
      },
    },*/
    MuiPaper: { // Target the Leaflet popup component
      styleOverrides: {
        root: {
          backgroundColor: "white",
          //backdropFilter: "blur(6px)",
          border: '1px solid grey'
 
        },
      },
    },
    /*MuiBox: { // Target the Leaflet popup component
        styleOverrides: {
          root: {
            backgroundColor: "rgba(241, 1, 240, 1)",
           // backdropFilter: "blur(8px)",
   
          },
        },
      },*/
    


  },
});

export default theme;
