import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    text: {
      primary: "#e5e6e5", // Set input text color to white
      secondary: "#e5e6e5", // Set FormHelperText color to white
    },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: '#5cec19',
            '& + .MuiSwitch-track': {
              backgroundColor: '#5cec19',
            },
          },
        },
        track: {
          backgroundColor: 'grey',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            backgroundColor: 'rgb(58, 71, 100)',
            width: '150px',
            height: '40px',
            color: 'white',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          },
          '& .MuiAutocomplete-popupIndicator': {//  the dropdown icon
            color: 'white', 
          },
          '& .MuiAutocomplete-clearIndicator': {// the clear icon (X button)
            color: 'white', 
          },
          '& .MuiAutocomplete-input': { // selected value
            color: 'white', 
            height: '20px',
            fontSize: '14px',
            marginTop: '-5px',
          },
        },
        paper: {//  the dropdown
          backgroundColor: 'rgb(58, 71, 100)', 
          color: 'white', 
          fontSize: '12px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            color: '#B2B2B2',
            marginTop: '-5px',
            fontSize: '14px',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#B2B2B2',
            
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e5e6e5", // Set default border color to white
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e5e6e5", // Set focused border color to white
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e5e6e5", // Set hover border color to white
          },
        },
      },
    },
    MuiInputLabel: {
      // Style the InputLabel
      styleOverrides: {
        root: {
          color: "#e5e6e5", // Set label color to white (default state)
          "&.Mui-focused": {
            // Add focused state styling
            color: "#e5e6e5", // Keep label white when focused
          },
        },
      },
    },
    MuiFormHelperText: {
      // Style the FormHelperText
      styleOverrides: {
        root: {
          color: "#e5e6e5", // Set FormHelperText color to white
        },
      },
    },
  },
});

export default theme;