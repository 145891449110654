import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Amplify} from 'aws-amplify';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import App from './App';
import '@aws-amplify/ui-react/styles.css'; 
import { Authenticator } from '@aws-amplify/ui-react';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/vars.css';
import { GlobalProvider } from './context/GlobalContext';


Amplify.configure({Auth: {
  Cognito: {
    userPoolClientId:(window._env_ && window._env_.REACT_APP_APPCLIENT_ID.length>0 && window._env_.REACT_APP_APPCLIENT_ID !== "%%APPCLIENT_ID%%")?window._env_.REACT_APP_APPCLIENT_ID:process.env.REACT_APP_APPCLIENT_ID, 
    userPoolId:(window._env_ && window._env_.REACT_APP_USERPOOL_ID.length>0 && window._env_.REACT_APP_USERPOOL_ID !== "%%USERPOOL_ID%%")?window._env_.REACT_APP_USERPOOL_ID:process.env.REACT_APP_USERPOOL_ID 
  }
}}); 





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<GlobalProvider>
  <React.StrictMode>
    <Router>
      <div className="auth-container">
      <Authenticator.Provider  hideSignUp loginMechanisms={['email']}>
        <App />
      </Authenticator.Provider>
      </div>
    </Router>
  </React.StrictMode>
  </GlobalProvider>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
