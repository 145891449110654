import React, { useState, useEffect, useRef } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import { Center, OrbitControls, Environment } from "@react-three/drei";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { Box3, Vector3 } from "three";

import "../styles/MapModelViewer.scss";
import ApiService from "../services/ApiService.js";
import CustomSnackbarAlert from "../components/CustomSnackbarAlert";

const MapModelViewer = ({ mapId, facilityId }) => {
  const [mapBlobURL, setMapBlobURL] = useState();
  const [map, setMap] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMsg, setAlertMsg] = useState("");

  useEffect(() => {}, [map]);

  useEffect(() => {
    if (facilityId) {
      if (mapId) {
        fetchMap(facilityId, mapId);
      } else {
        fetchMap(facilityId, null);
      }
    }
  }, [mapId, facilityId]);

  const fetchMap = async (facilityId, mapId) => {
    try {
      const res = await ApiService.getMapModel(facilityId, mapId);
      console.log(res.status);
      if (res.status === 200) {
        setMap(res.data);
        const blob = new Blob([res.data], { type: "application/octet-stream" });
        const url = URL.createObjectURL(blob);
        setMapBlobURL(url);
      } else if (res.status === 404) {
        setAlertMsg(`This facility does NOT have any maps yet!`);
        setAlertSeverity("error");
      } else {
        setAlertMsg(`Unexpected status code: ${res.status}`);
        setAlertSeverity("error");
      }
    } catch (error) {
      if (error.response.status === 404) {
        setAlertMsg(`This facility does NOT have any maps yet!`);
        setAlertSeverity("error");
      } else {
        setAlertMsg("Map could not be loaded!");
        setAlertSeverity("error");
      }
    }
  };

  function Model(props) {
    const obj = useLoader(OBJLoader, mapBlobURL);
    const ref = useRef();

    useEffect(() => {
      if (obj) {
        const box = new Box3().setFromObject(obj);
        const center = box.getCenter(new Vector3());
        obj.position.set(-center.x, -center.y, -center.z); 
      }
    }, [obj]);

    return <primitive object={obj} ref={ref} {...props} />;
  }

  return (
    <div className="dexx">
      <CustomSnackbarAlert
        severity={alertSeverity}
        duration={2500}
        message={alertMsg}
        onAlertClose={() => setAlertMsg("")}
      ></CustomSnackbarAlert>

      <Canvas
        shadows
        camera={{ fov: 35, position: [80, 80, 0] }}
        style={{ width: "100vw", height: "calc(100vh - 70px)" }}
      >
        <color attach="background" args={["#3a4764"]} />
        <group>
          <ambientLight intensity={1.8}/>
          <Center position={[0, -3.5, 0]}>
            {mapBlobURL && <Model scale={1} rotation={[-90 * Math.PI/180, 0, 90 * Math.PI/180]} />}
          </Center>
          <directionalLight intensity={0.8} position={[-20,10,70]}/>
          <directionalLight intensity={2.5} position={[10,10,-20]}/>
        </group>
        <OrbitControls minPolarAngle={0} maxPolarAngle={Math.PI / 2} />
        <Environment preset="city" />
      </Canvas>
    </div>
  );
};

export default React.memo(MapModelViewer);