import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import "../styles/CustomSnackbarAlert.scss";

const CustomSnackbarAlert = ({ severity, message, onAlertClose, duration }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  useEffect(() => {
    setAlertMsg(message);
    setAlertSeverity(severity);
  }, [severity, message]);

  useEffect(() => {
    if (alertMsg !== "") {
      setOpenAlert(true);
    }
  }, [alertSeverity, alertMsg]);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      setOpenAlert(false);
      onAlertClose();
      return;
    }
    setOpenAlert(false);
    onAlertClose();
  };

  return (
    <Snackbar
      className="csa-snackbar"
      open={openAlert}
      autoHideDuration={duration}
      onClose={handleCloseAlert}
    >
      <Alert
      variant="filled"
        onClose={handleCloseAlert}
        severity={alertSeverity}
        className={alertSeverity === "success" ? "csa-alert csa-bg-green csa-border-green" : "csa-alert csa-bg-red csa-border-red"}
      >
        {alertMsg}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbarAlert;
