import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import Help from './pages/HelpPage';
import Profile from './pages/ProfilePage';
import Map from './pages/MapPage';
import ThreeD from './pages/ThreeDPage';
import Facilities from './pages/FacilitiesPage';
import NotFoundPage from './pages/NotFoundPage';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Header from './components/Header';
import { Authenticator } from '@aws-amplify/ui-react';
import valeoLogo from './static/images/valeoLogo.png';
import UploadPage from './pages/UploadPage';
import { fetchAuthSession } from 'aws-amplify/auth';


import './App.css';
const App = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const navigate = useNavigate();

  useEffect(() => {
    const apiBaseURL = (window._env_ && window._env_.REACT_APP_BACKEND_API_BASE_URL.length > 0 && window._env_.REACT_APP_BACKEND_API_BASE_URL !== "%%BACKEND_API_BASE_URL%%")?
     window._env_.REACT_APP_BACKEND_API_BASE_URL : 
     process.env.REACT_APP_BACKEND_API_BASE_URL;
  }, []);

  useEffect(() => {
    if (authStatus === 'unauthenticated') {
      navigate('/login');
    }
  }, [authStatus, navigate]);



  

  useEffect(() => {
    const refreshToken = async () => {
      try {
        const session = await fetchAuthSession();
        const currentTime = Math.floor(Date.now() / 1000);
        const expiresAt = session.tokens.accessToken.payload.exp;

        // Refresh token if it will expire in the next 30 seconds
        if (expiresAt - currentTime < 30) {
          await fetchAuthSession({ forceRefresh: true });
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
      }
    };
    const intervalId = setInterval(refreshToken, 25000); // Check every 25 seconds
    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);




  if (authStatus === 'configuring' || authStatus === 'loading') {
    // return loading while checking the auth status
    return <div>Loading...</div>;
  }

  return (
    
    <div className='main'>
      <Header />
     <div className='routeCont'>
     {authStatus === 'unauthenticated' &&
     <img alt="Valeo Logo" className='vlogoXl' src={valeoLogo} />
  }
     <Routes>
     <Route path="/login" element={authStatus === 'authenticated' ? <Navigate to="/map" /> : (
            <div className="authenticator-glassmorphism">
              <Authenticator hideSignUp loginMechanisms={['email']} />
            </div>
          )} />
        
        <Route path="/help" element={authStatus === 'authenticated' ? <Help /> : <Navigate to="/login" />} />
        <Route path="/profile" element={authStatus === 'authenticated' ? <Profile /> : <Navigate to="/login" />} />
        <Route path="/map" element={authStatus === 'authenticated' ? <Map /> : <Navigate to="/login" />} />
        <Route path="/upload" element={authStatus === 'authenticated' ? <UploadPage /> : <Navigate to="/login" />} />
        <Route path="/3d/:facilityId/latest" element={authStatus === 'authenticated' ? <ThreeD /> : <Navigate to="/login" />} />
        <Route path="/3d/:mapId" element={authStatus === 'authenticated' ? <ThreeD /> : <Navigate to="/login" />} />
        <Route path="/facilities" element={authStatus === 'authenticated' ? <Facilities /> : <Navigate to="/login" />} />
        <Route path="/" element={authStatus === 'authenticated' ? <Map /> : <Navigate to="/login" />} />
        <Route path="*" element={authStatus === 'authenticated' ? <NotFoundPage /> : <Navigate to="/login" />} />
      </Routes>
     </div>
      
     
    
    </div>
  );
};

export default App;
