import React, { useEffect, useState, useCallback } from "react";
import "../styles/FacilitiesPage.scss";
import FacilityBasicCard from "../components/FacilityBasicCard";
import FacilitiesFilters from "../components/FacilitiesFilters.jsx";
import ApiService from "../services/ApiService.js";
import CustomSnackbarAlert from "../components/CustomSnackbarAlert";


const FacilitiesPage = () => {
  const [facilityObjectsFiltered, setFacilityObjectsFiltered] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMsg, setAlertMsg] = useState("");

  /**
   * uppon load , gets facilities list from api
   */
useEffect(() => {
  const getFacilities = async () => {
    try {
      const res = await ApiService.getFacilities();
      if (res.status === 200) {
        setFacilities(res.data);
        setFacilityObjectsFiltered(res.data);
      } else {
        console.error(`Unexpected status code: ${res.status}`);
        setAlertMsg(`Unexpected status code: ${res.status}`);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error('Error fetching facilities:', error);
    }
  };

  getFacilities();
}, []); 






  useEffect(() => {
  }, [facilities]);

  /**
   * func passed to child in order for child to emit filtered facilities list here.
   */
  const handleFilterChange = useCallback((filteredObjects) => {
    setFacilityObjectsFiltered(filteredObjects);
  }, []);

  return (
    <div className="facilitypage-main-container">
          <CustomSnackbarAlert
        severity={alertSeverity}
        duration={2500}
        message={alertMsg}
        onAlertClose={() => setAlertMsg("")}
      ></CustomSnackbarAlert>

      <div className="fp-facilities-filters"><FacilitiesFilters facilities={facilities} handleFilterChange={handleFilterChange} /></div>
      
      <div className="facilities-container">
        {facilityObjectsFiltered.map((facility, index) => (
          <div key={index} className="facility-card-wrapper">
            <FacilityBasicCard facilityObject={facility} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FacilitiesPage;