import React from "react";
import "../styles/CustomAnimatedVLogo.scss";
import logo from "../static/images/valeoLogo.png"
import logo2 from "../static/images/Park4ULocate_white.svg"
import { useNavigate } from 'react-router-dom';


const CustomAnimatedVLogo = () => {
  const navigate = useNavigate();
 
  const gotoMain = () => {
    navigate("/map")
  }
  return (
    <div className="cavl-main-container">
     
      <img onClick={gotoMain} alt="Logo" className='cavl-logo' src={logo} />
      <img onClick={gotoMain} alt="Logo" className='cavl-logo2' src={logo2} />
    
      
    </div>
  );
};

export default CustomAnimatedVLogo;
