import React, { useEffect, useState } from "react";
import "../styles/UploadPage.scss";
import { IconButton } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import ApiService from "../services/ApiService.js";
import CustomSnackbarAlert from "../components/CustomSnackbarAlert";

const UploadPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const timer = React.useRef();
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [traceObjectsArray, setTraceObjectsArray] = useState([]);

  useEffect(() => {
    let currentTimer = timer.current;

    return () => {
      clearTimeout(currentTimer);
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Component unmounted");
      }
    };
  }, [cancelTokenSource]);

  /**
   * converts Unix timestamp (in milliseconds) to custom date format
   * returns dd/mm/yyyy hh:mm:ss
   */
  function convertUnixTimestamp(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }

  /**
   * when clicking the upload btn
   * sends files to api
   * displays success/error msgs in snackbar
   * handles loading
   */
  const handleFileUpload = async () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);

      const source = axios.CancelToken.source();
      setCancelTokenSource(source);

      const MAX_BATCH_SIZE_MB = 10;
      const MAX_BATCH_SIZE_BYTES = MAX_BATCH_SIZE_MB * 1024 * 1024;

      try {
        let currentBatch = [];
        let currentBatchSize = 0;

        for (const traceObject of traceObjectsArray) {
          const traceObjectSize = new Blob([JSON.stringify(traceObject)]).size;

          if (currentBatchSize + traceObjectSize > MAX_BATCH_SIZE_BYTES) {
            // Upload the current batch
            const res = await ApiService.uploadFiles(
              currentBatch,
              source.token
            );
            if (res.status !== 201) {
              throw new Error(`Unexpected status code: ${res.status}`);
            }

            // Reset for next batch
            currentBatch = [];
            currentBatchSize = 0;
          }
          currentBatch.push(traceObject);
          currentBatchSize += traceObjectSize;
        }

        // Upload the remaining batch
        if (currentBatch.length > 0) {
          const res = await ApiService.uploadFiles(currentBatch, source.token);
          if (res.status !== 201) {
            throw new Error(`Unexpected status code: ${res.status}`);
          }
        }
        setAlertMsg(`Files uploaded successfully!`);
        setAlertSeverity("success");
        setSuccess(true);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Upload canceled:", error.message);
        } else {
          console.error("Error uploading file:", error);
          setAlertMsg("Error uploading file");
          setAlertSeverity("error");
        }
        setSuccess(false);
      } finally {
        setLoading(false);
      }
    }
  };

  /**
   * after selecting files
   * can handle multiple files
   * creates table objects for visualization
   * creates upload objects for api
   */
  const handleFileSelect = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.click();

    input.addEventListener("change", async (event) => {
      const files = event.target.files;
      const fileListArray = [];
      const objectArray = [];

      for (let i = 0; i < files.length; i++) {
        // for the table objects
        let file = files[i];
        const facilityId = await extractFacilityIdFromJson(file);
        const timeStamp = await extractTimeStampFromJson(file);

        fileListArray.push({
          timeStamp: timeStamp,
          name: file.name,
          size: file.size,
          type: file.type,
          facilityId: facilityId,
          hasValidExtention:
            file.name.endsWith(".dat") || file.name.endsWith(".json"),
          hasValidSize: file.size <= 15000000,
          hasValidType: file.type === "" || file.type === "application/json",
          hasMatchingPair: false,
          hasValidJsonKeys: true,
          message: "",
        });

        // for the upload ,
        if (file.name.endsWith(".json")) {
          //if ext .json
          const hasRequiredKeys = await checkJsonKeys(file); // check if this json file has all req keys ("facilityId", "vehicleModel", "startTime" )
          if (hasRequiredKeys) {
            const correspondingDatFile = Array.from(files).find(
              (f) => f.name === file.name.replace(".json", ".dat")
            ); //check if corresponding .dat

            if (correspondingDatFile && correspondingDatFile.size < 15000000) {
              // check if corresponding .dat has valid size
              const jsonContent = await readJsonFile(file); // read json file to get the values
              const { base64String, sha256Base64 } = await convertDatToBase64(
                correspondingDatFile
              ); // make base64 of dat file and sha256 of base64
              const object = {
                // build object
                facilityId: jsonContent.facilityId,
                vehicleModel: jsonContent.vehicleModel,
                startTime: Math.floor(Date.now() / 1000), //jsonContent.startTime
                contents: base64String,
                sha256: sha256Base64,
              };
              objectArray.push(object);
            }
          } else {
            // invalid json keys
            fileListArray[i].hasValidJsonKeys = false;
            // find the corresponding .dat file in fileListArray and set its .hasValidJsonKeys to false
            const datIndex = fileListArray.findIndex(
              (f) => f.name === file.name.replace(".json", ".dat")
            );
            if (datIndex !== -1) {
              fileListArray[datIndex].hasValidJsonKeys = false;
            }
          }
        }
      }

      checkForMatchingPairs(fileListArray);
      setFileList(fileListArray);
      setTraceObjectsArray(objectArray);
    });
  };


  /*
  * If json file then return facilityId value , else return ""
  */
  const extractFacilityIdFromJson = async (file) => {
    return new Promise((resolve, reject) => {
      if ( file.type !== "application/json") {
        resolve("");
        return;
      }
  
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const json = JSON.parse(event.target.result);
          if (json.facilityId) {
            resolve(json.facilityId);
          } else {
            resolve("");
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
          reject(error);
        }
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        reject(error);
      };
      reader.readAsText(file);
    });
  };

  /*
  * If json file then return startTime value , else return ""
  */
  const extractTimeStampFromJson = async (file) => {
    return new Promise((resolve, reject) => {
      if ( file.type !== "application/json") {
        resolve(0);
        return;
      }
  
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const json = JSON.parse(event.target.result);
          if (json.startTime) {
            resolve(json.startTime);
          } else {
            resolve(0);
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
          reject(error);
        }
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        reject(error);
      };
      reader.readAsText(file);
    });
  };
  
  

  /**
   *
   */
  const convertDatToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async () => {
        const base64String = reader.result.split(",")[1];
        const sha256Base64 = await calculateSHA256Base64(base64String);
        resolve({ base64String, sha256Base64 });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  /**
   *
   */
  const readJsonFile = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const json = JSON.parse(event.target.result);
          resolve(json);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  };

  /**
   * checks if the json file has all the required keys
   * returns boolean
   */
  const checkJsonKeys = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const json = JSON.parse(event.target.result);
          if (json.facilityId && json.vehicleModel && json.startTime) {
            resolve(true);
          } else {
            resolve(false);
          }
        } catch (error) {
          resolve(false);
        }
      };
      reader.onerror = (error) => {
        resolve(false);
      };
      reader.readAsText(file);
    });
  };

  /**
   * calc and returns the sha256 of a file
   */
  /*const calculateSHA256 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const wordArray = CryptoJS.lib.WordArray.create(reader.result);
        const hash = CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Hex);
        resolve(hash);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };*/

  const calculateSHA256Base64 = async (base64String) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(base64String);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  };

  /*const calculateSHA256 = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  };*/

  /**
   *
   */
  const checkForMatchingPairs = (files) => {
    const fileNameMap = {};
    files.forEach((file) => {
      const nameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
      if (fileNameMap[nameWithoutExtension]) {
        fileNameMap[nameWithoutExtension].push(file);
      } else {
        fileNameMap[nameWithoutExtension] = [file];
      }
    });
  
    // Step 1: Find matching pairs and set hasMatchingPair flag
    for (let key in fileNameMap) {
      const fileListArray = fileNameMap[key];
      const hasDat = fileListArray.some((file) => file.name.endsWith(".dat"));
      const hasJson = fileListArray.some((file) => file.name.endsWith(".json"));
      if (hasDat && hasJson) {
        fileListArray.forEach((file) => {
          file.hasMatchingPair = true;
        });
      }
    }
  
    // Step 2: Set facilityId for .dat files to match the corresponding .json file
    for (let key in fileNameMap) {
      const fileListArray = fileNameMap[key];
      const jsonFile = fileListArray.find((file) => file.name.endsWith(".json"));
      const datFile = fileListArray.find((file) => file.name.endsWith(".dat"));
      if (jsonFile && datFile) {
        datFile.facilityId = jsonFile.facilityId;
        datFile.timeStamp = jsonFile.timeStamp;
      }
    }
  
    // Step 3: Set file messages
    files.forEach((file) => {
      if (
        file.hasMatchingPair &&
        file.hasValidExtention &&
        file.hasValidSize &&
        file.hasValidJsonKeys &&
        file.hasValidType
      ) {
        file.message = "valid";
      } else {
        if (!file.hasMatchingPair && file.name.endsWith(".dat")) {
          file.message = "No matching .json pair";
        } else if (!file.hasMatchingPair && file.name.endsWith(".json")) {
          file.message = "No matching .dat pair";
        } else if (!file.hasValidExtention || !file.hasValidType) {
          file.message = "Invalid file extension (dat or json)";
        } else if (!file.hasValidSize) {
          file.message = "File is too large (max 15MB)";
        } else if (!file.hasValidJsonKeys) {
          file.message = "Invalid json key";
        }
      }
    });
  
    return files;
  };
  

  const handleDeleteBtn = (index) => {
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    const updatedFileList = checkForMatchingPairs(newFileList);
    updatedFileList.sort((a, b) => a.name.localeCompare(b.name));
    setFileList(updatedFileList);
  };

  const hasInvalidFiles = () => {
    return fileList.some((file) => file.message !== "valid");
  };

  useEffect(() => {
    if (fileList.length > 0) {
      setActiveStep(1);
    } else {
      setActiveStep(0);
    }
  }, [fileList]);

  const handleBackBtn = () => {
    setActiveStep(0);
    setFileList([]);
  };

  /*const handleCancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Upload canceled by user");
    }
  };*/

  return (
    <div className="uploadpage-main-container">
      <CustomSnackbarAlert
        severity={alertSeverity}
        duration={2500}
        message={alertMsg}
        onAlertClose={() => setAlertMsg("")}
      ></CustomSnackbarAlert>
      {activeStep === 0 && (
        <div className="uploadpage-container">
          <IconButton
            className="uploadpage-select-btn"
            onClick={handleFileSelect}
          >
            <UploadFileIcon className="uploadpage-select-icn" />
            <span className="uploadpage-upload-btn-text selectfiles">
              Select Files
            </span>
          </IconButton>
        </div>
      )}

      {activeStep === 1 && (
        <div className="uploadpage-container">
          <div className="uploadpage-table-container">
            <Table
              className="uploadpage-table"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className="uploadpage-table-cell" align="center">
                    Name
                  </TableCell>
                  <TableCell className="uploadpage-table-cell" align="center">
                    Created
                  </TableCell>
                  <TableCell className="uploadpage-table-cell" align="center">
                    Size (kB)
                  </TableCell>
                  <TableCell className="uploadpage-table-cell" align="center">
                    Location
                  </TableCell>
                  <TableCell className="uploadpage-table-cell" align="center">
                    Valid
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {fileList.map((row, index) => {
  // Conditional rendering based on row.message and row.name
  if (row.message === "valid" && row.name.endsWith(".json")) {
    return null;
  }

  // Modify row.name if it ends with .dat and row.message is valid
  const displayName = row.message === "valid" && row.name.endsWith(".dat")
    ? row.name.slice(0, -4)
    : row.name;

  return (
    <TableRow
      key={row.name}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell
        className="uploadpage-table-cell"
        align="center"
        component="th"
        scope="row"
      >
        {displayName}
      </TableCell>
      <TableCell className="uploadpage-table-cell" align="center">
        {convertUnixTimestamp(row.timeStamp)}
      </TableCell>
      <TableCell className="uploadpage-table-cell" align="center">
        {(row.size / 1000).toFixed(2)}
      </TableCell>
      <TableCell className="uploadpage-table-cell" align="center">
        {row.facilityId}
      </TableCell>
      <TableCell
        align="center"
        className={
          row.message !== "valid"
            ? "table-cell-invalid-msg"
            : "table-cell-valid-msg"
        }
      >
        {row.message !== "valid" && (
          <div>
            {row.message}{" "}
            <IconButton
              className="uploadpage-delete-btn"
              onClick={() => handleDeleteBtn(index)}
            >
              <DeleteForeverIcon className="table-cell-invalid-icn" />
            </IconButton>
          </div>
        )}
        {row.message === "valid" && (
          <TaskAltIcon className="table-cell-valid-icn" />
        )}
      </TableCell>
    </TableRow>
  );
})}

              </TableBody>
            </Table>
          </div>

          <div className="uploadpage-btn-container">
            <IconButton
              className={success ? "disabled-btn disabled-icn" : ""}
              onClick={success || loading ? () => {} : handleBackBtn}
            >
              <ArrowBackIcon className="uploadpage-back-icn" />
            </IconButton>

            <div className="uploadpage-loader-container">
              <Fab
                aria-label="save"
                color="primary"
                sx={{
                  boxShadow: "none",
                  ...(success && {
                    cursor: "default",
                    bgcolor: green[500],
                    "&:hover": {
                      bgcolor: green[500],
                    },
                  }),
                  ...(!success && {
                    bgcolor: "transparent",
                    "&:hover": {
                      bgcolor: "transparent",
                    },
                  }),
                }}
                className={
                  hasInvalidFiles()
                    ? "uploadpage-upload-btn disabled-btn"
                    : "uploadpage-upload-btn"
                }
                onClick={
                  hasInvalidFiles() || success || loading
                    ? () => {}
                    : handleFileUpload
                }
              >
                {success ? (
                  <CheckIcon />
                ) : (
                  <CloudUploadIcon
                    className={
                      hasInvalidFiles()
                        ? "uploadpage-upload-icn disabled-icn"
                        : "uploadpage-upload-icn"
                    }
                  />
                )}
              </Fab>
              {loading && (
                <CircularProgress
                  size={68}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: -6,
                    left: -6,
                    zIndex: 1,
                  }}
                />
              )}
              {/*loading && (
                <IconButton onClick={handleCancelUpload}>
                  <CancelIcon className="uploadpage-cancel-icn" />
                </IconButton>
              )*/}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadPage;
