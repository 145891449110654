import React, { useState } from "react";
import "../styles/FacilityBasicCard.scss";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import FacilityDetailsCard from "./FacilityDetailsCard";

const FacilityBasicCard = ({ facilityObject }) => {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const handleOpenDetailsModal = () => setOpenDetailsModal(true);
  const handleCloseDetailsModal = () => setOpenDetailsModal(false);
  const navigate = useNavigate();

  const threeDBtnClick = () => {
    const facility3DPath = `/3d/${facilityObject.facilityId}/latest`;
    navigate(facility3DPath); 
  };

  return (
    <div className="map-popup-card-main"> 
      <div>
        <div className="map-popup-card-chip-container">
          {facilityObject.isLevel4Permitted && (
            <Chip
              className="chip-style chip-first chip-l4"
              label="L 4"
              size="small"
            />
          )}
          {facilityObject.isExternalMapFacility && (
            <Chip
              className="chip-style chip-first chip-csm"
              label="CSM"
              size="small"
            />
          )}
          {facilityObject.isExternalMapFacility === false && (
            <Chip
              className="chip-style chip-first chip-tmp"
              label="TMP"
              size="small"
            />
          )}
        </div>

        <div className="popup-card-nameaddress-container">
          <span className="map-popup-card-title">
            {facilityObject.description}
          </span>
          <span className="map-popup-card-subtitle">
            {facilityObject.physicalAddress}
          </span>
          <span className="map-popup-card-subtitle">
            Location : {facilityObject.facilityId}
          </span>
        </div>
      </div>

    {facilityObject.isExternalMapFacility !== false && (
      <div className="map-popup-card-buttons">
        <Button
          onClick={threeDBtnClick}
          className="map-popup-card-button-3d"
          size="small"
          variant="contained"
          sx={{
            textTransform: "none",
            backgroundColor: "rgb(58, 71, 100)",
            height: "26px",
            width: "45%",
          }}
          disableElevation
        >
          3D View
        </Button>
        <Button
          onClick={handleOpenDetailsModal}
          className="map-popup-card-button-details"
          size="small"
          variant="contained"
          sx={{
            textTransform: "none",
            backgroundColor: "rgb(58, 71, 100)",
            height: "26px",
            width: "45%",
          }}
          disableElevation
        >
          Details
        </Button>
      </div>
    )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDetailsModal}
        onClose={handleCloseDetailsModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openDetailsModal}>
          <Box className="detailsModal">
            <FacilityDetailsCard facilityId={facilityObject.facilityId} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default FacilityBasicCard;
