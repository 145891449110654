import React, { useEffect, useState, useContext } from "react";
import "../styles/FacilityDetailsCard.scss";
import MapsTable from "./MapsTable.jsx";
import TracesTable from "./TracesTable.jsx";
import Chip from "@mui/material/Chip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CustomSnackbarAlert from "./CustomSnackbarAlert.jsx";
import ApiService from "../services/ApiService.js";
import { GlobalContext } from "../context/GlobalContext.js";
import CircularProgress from '@mui/material/CircularProgress';

const FacilityDetailsCard = ({ facilityId }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { incompleteJobs } = state;
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [facilityDetails, setFacilityDetails] = useState(null);

  /**
   * calls api and sets facilityinfo from response
   * handles alert msgs
   */
  const getFacilityInfo = async (facilityId) => {
    try {
      const res = await ApiService.getFacilityDetails(facilityId);
      if (res.status === 200) {
        // Sort Maps by creationDate in descending order
        await res.data.Maps.sort((a, b) => b.creationDate - a.creationDate);

        // Sort Traces by startTime in descending order
        await res.data.Traces.sort((a, b) => b.startTime - a.startTime);
        setFacilityDetails(res.data);
       
      } else {
        console.error(`Unexpected status code: ${res.status}`);
        setAlertMsg(`Unexpected status code: ${res.status}`);
        setAlertSeverity("error");
      }
    } catch (error) {
      setAlertMsg("Unable to fetch facility details !");
      setAlertSeverity("error");
      console.error("Unable to fetch facility details", error);
    } finally {
    }
  };

  useEffect(() => {
    if (facilityId) {
      getFacilityInfo(facilityId);
    }
  }, [facilityId]);

  useEffect(() => {}, [facilityDetails]);
  useEffect(() => {}, [incompleteJobs]);

  /**
   * alert msg when facility id is copied to clipboard
   */
  const handleClickCopyId = () => {
    setAlertMsg("Facility ID has been copied to clipboard !");
    setAlertSeverity("success");
  };

  /**
   * alert msg when facility address is copied to clipboard
   */
  const handleClickCopyAddress = () => {
    setAlertMsg("Facility address has been copied to clipboard !");
    setAlertSeverity("success");
  };

  return (
    <div>
      <CustomSnackbarAlert
        severity={alertSeverity}
        message={alertMsg}
        duration={2500}
        onAlertClose={() => setAlertMsg("")}
      ></CustomSnackbarAlert>

      {facilityDetails ? (
        <div className="fdc-main-container">
          <div className="fdc-container-wrapper-1">
            <div className="fdc-container-1">
              <span
                className="fdc-container-main-title"
                style={{ marginBottom: "20px" }}
              >
                General
              </span>

              <div className="fdc-chip-container">
                {facilityDetails.General.isLevel4Permitted && (
                  <Chip
                    className="chip-style chip-first chip-l4"
                    label="L 4"
                    size="small"
                  />
                )}
                {facilityDetails.General.isExternalMapFacility && (
                  <Chip
                    className="chip-style chip-first chip-csm"
                    label="CSM"
                    size="small"
                  />
                )}
                {facilityDetails.General.isExternalMapFacility === false && (
                  <Chip
                    className="chip-style chip-first chip-tmp"
                    label="TMP"
                    size="small"
                  />
                )}
              </div>

              <span className="fdc-container-title">
                Location :{" "}
                <span className="fdc-container-title-value">
                  {facilityDetails.General.facilityId}
                  <CopyToClipboard
                    text={facilityDetails.General.facilityId}
                    onCopy={() => handleClickCopyId()}
                  >
                    <IconButton className="fdc-container-1-button">
                      <ContentCopyIcon className="fdc-copy-icon" />
                    </IconButton>
                  </CopyToClipboard>
                </span>
              </span>

              {/*<span className="fdc-container-title">
                Location (GCJ02) :{" "}
                <span className="fdc-container-title-value">
                  {facilityDetails.General.centerPosition[0]},{" "}
                  {facilityDetails.General.centerPosition[1]}
                </span>
              </span>*/}
              <span className="fdc-container-title">
                Description :{" "}
                <span className="fdc-container-title-value">
                  {facilityDetails.General.description}
                </span>
              </span>
              <span className="fdc-container-title">
                Address :{" "}
                <span className="fdc-container-title-value">
                  {facilityDetails.General.physicalAddress}{" "}
                  <CopyToClipboard
                    text={facilityDetails.General.physicalAddress}
                    onCopy={() => handleClickCopyAddress()}
                  >
                    <IconButton className="fdc-container-1-button">
                      <ContentCopyIcon className="fdc-copy-icon" />
                    </IconButton>
                  </CopyToClipboard>
                </span>
              </span>
            </div>
            <div className="fdc-container-2">
              <div className="fdc-container-2-top-wrapper">
                <span className="fdc-container-main-title">Facility maps</span>{" "}
              </div>
              <div className="fdc-container-2-bottom-wrapper">
                <MapsTable
                  facilityDetails={facilityDetails}
                  getFacilityInfo={getFacilityInfo}
                />
              </div>
            </div>
          </div>
          <div className="fdc-container-wrapper-2">
            <div className="fdc-container-3">
              <span className="fdc-container-main-title">Processes</span>
              <div className="fdc-container-3-progress-container">
                {incompleteJobs && incompleteJobs.length > 0 && (
                  incompleteJobs.map((job) => (
                    job.facilityId === facilityId && (
                    <div className="fdc-jobcard" key={job.jobId}>
                      <div className="fdc-jobcard-top">{job.jobId}</div>
                      <div className="fdc-jobcard-bot"><span className="fdc-jobcard-textb">{job.jobStatus.status}</span> { job.jobStatus.status === "RUNNING" && (
                        <div className="pulse-container">  
                        <div className="pulse-bubble pulse-bubble-1"></div>
                        <div className="pulse-bubble pulse-bubble-2"></div>
                        <div className="pulse-bubble pulse-bubble-3"></div>
                      </div>
                      )}</div>
                    </div>)
                   ))
                /*<LinearProgress
                  sx={{
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#82E600",
                    },
                    backgroundColor: "transparent",
                  }}
                  className="fdc-progress-bar"
                  variant="determinate"
                  value={progress}
                />*/
              )}
              </div>
            </div>

            <div className="fdc-container-4">
              <div className="fdc-container-4-top-wrapper">
                <span className="fdc-container-main-title">Traces</span>
              </div>
              <div className="fdc-container-4-bottom-wrapper">
                <TracesTable
                  facilityDetails={facilityDetails}
                  setFacilityDetails={setFacilityDetails}
                  getFacilityInfo={getFacilityInfo}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.4em",
            width: "300px",
            height: "80px",
          }}
        >
          Loading...
        </div>
      )}
    </div>
  );
};

export default FacilityDetailsCard;
