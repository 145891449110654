// modules
import React, { useEffect } from "react";
import "../styles/ThreeDPage.scss";
import MapModelViewer from "../components/MapModelViewer";
import { useParams } from "react-router-dom";
// components


const ThreeDPage = () => {
// Get mapId and facilityId from URL
const { mapId, facilityId } = useParams(); 

useEffect(() => {
}, []);

  useEffect(() => {
  }, [ mapId, facilityId]); 


const extractFacilityIdAfterColon = (inputString) => {
  const parts = inputString.split(':');
  return parts.length > 1 ? parts[1] : ''; 
};


  
  return (
    <div className="main-container">
      
      <MapModelViewer mapId={mapId === undefined ? null:mapId} facilityId={facilityId === undefined? extractFacilityIdAfterColon(mapId):facilityId} />
      
    </div>
  );
};

export default ThreeDPage;