import React, { useEffect, useState, useContext } from "react";
import "../styles/TracesTable.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IconButton from "@mui/material/IconButton";
import CustomSnackbarAlert from "../components/CustomSnackbarAlert";
import Button from "@mui/material/Button";
import ApiService from "../services/ApiService.js";
import { GlobalContext } from "../context/GlobalContext.js";

const TracesTable = ({ facilityDetails, getFacilityInfo }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { incompleteJobs } = state;
 
  //const [latestMapCreateTime, setLatestMapCreateTime] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [tableData, setTableData] = useState(facilityDetails.Traces);
  //const [showUnprocessedOnly, setShowUnprocessedOnly] = useState(false);
  const [deleteConfirmationRow, setDeleteConfirmationRow] = useState(null);
  //const [loading, setLoading] = useState(false);

  /**
   * converts Unix timestamp (in milliseconds) to custom date format
   * returns dd/mm/yyyy hh:mm:ss
   */
  function convertUnixTimestamp(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }

  /**
   * when facilityDetails gets data, sets the most recent map creation unix time
   */
  useEffect(() => {
    /*if(facilityDetails.Maps && facilityDetails.Maps.length > 0 ){
      //set latest map create time
      const mostRecentMapTime = facilityDetails.Maps.reduce((max, obj) => {
        return obj.creationDate > max ? obj.creationDate : max;
      }, facilityDetails.Maps[0].creationDate);
      setLatestMapCreateTime(mostRecentMapTime);
      
    }else{
      setLatestMapCreateTime(0);
    }*/
    
  }, [facilityDetails]);

  useEffect(() => {
    setTableData(facilityDetails.Traces);
  }, [facilityDetails]);

  /**
   * if incomplete jobs in store, then updates their status every 3s
   * when complete or failed , removes them from store
   * handles loading and snackbar alerts
   */
  useEffect(() => {
    if (incompleteJobs.length > 0) {
      //setLoading(true);

        /**
         * from a job id gets the current status
         * updates store accordingly (removes completed/failed jobs)
         * handles alert msgs
         */
      const checkJobStatus = async (job) => {
        try {
          const jobStatusRes = await ApiService.getMapJobStatus(job.jobId);
          const jobStatus = jobStatusRes.data.status;
          if (jobStatusRes.status === 200) {
            if (jobStatus === "COMPLETED") {
              console.log(`JOB COMPLETED! (job id: ${job.jobId})`)
              setAlertMsg(
                `Facility map has been created! (job id: ${job.jobId})`
              );
              setAlertSeverity("success");
              await getFacilityInfo(facilityDetails.General.facilityId); // Trigger data refresh from details card
              dispatch({ type: "REMOVE_JOB", payload: job.jobId });
            } else if (jobStatus === "FAILED") {
              console.log(`JOB FAILED! (job id: ${job.jobId})`)
              setAlertMsg("Facility map creation failed!");
              setAlertSeverity("error");
              dispatch({ type: "REMOVE_JOB", payload: job.jobId });
            }
          } else {
            setAlertMsg("Facility map creation failed!");
            setAlertSeverity("error");
          }
        } catch (statusError) {
          console.error("Error fetching job status", statusError);
          setAlertMsg("Error fetching job status");
          setAlertSeverity("error");
        }
      };

      const intervalId = setInterval(() => {
        incompleteJobs.forEach((job) => {
          checkJobStatus(job);
        });
      }, 3000);

      // Cleanup interval on component unmount or when incompleteJobs becomes empty
      return () => clearInterval(intervalId);
    } else {
      //setLoading(false);
    }
  }, [
    incompleteJobs,
    dispatch,
    facilityDetails.General.facilityId,
    getFacilityInfo,
  ]);

  /**
   * sends list of trace ids to api for map creation.
   * api returns a job id. calls the api with the job id to get the job status
   * if status 200 then adds job to store
   * displays success/error msgs in snackbar accordingly
   */
  const handleCreateMap = async () => {
    if(selectedRowsIds.length === 0){
      setAlertMsg("You must select trace(s) to create map");
      setAlertSeverity("error");
    }else{
      try {
        const res = await ApiService.createMap(selectedRowsIds);
        
        if (res.status === 200) {
          try {
            const jobId = res.data.job_id;
            const jobStatusRes = await ApiService.getMapJobStatus(jobId);
            const jobStatus = jobStatusRes.data;
            if (jobStatusRes.status === 200) {
              //add job to store
              const newJob = { jobId: jobId, jobStatus: jobStatus, facilityId: facilityDetails.General.facilityId };
              dispatch({ type: "ADD_JOB", payload: newJob });
            } else {
              setAlertMsg("Facility map creation failed!");
              setAlertSeverity("error");
            }
          } catch (statusError) {
            console.error("Error fetching job status", statusError);
            setAlertMsg("Error fetching job status");
            setAlertSeverity("error");
          }
                  // Reset selected rows after map creation
                  setSelectedRowsIds([]);
                  setSelectAll(false);
        } else {
          console.error(`Unexpected status code: ${res.status}`);
          setAlertMsg(`Unexpected status code: ${res.status}`);
          setAlertSeverity("error");
        }
      } catch (error) {
        console.error("Error creating map", error);
        setAlertMsg("Facility map creation failed!");
        setAlertSeverity("error");
      }
    }
   
  };

  /**
   * when tableData changes , update selectedRowsIds
   */
  useEffect(() => {
    // Use a functional update to avoid the dependency on selectedRowsIds
    setSelectedRowsIds((prevSelectedRowsIds) =>
      prevSelectedRowsIds.filter((id) =>
        tableData.some((row) => row.traceId === id)
      )
    );
  }, [tableData]); // Dependency array now only includes tableData
  

  /**
   * updates selectedRowsIds when a checkbox is checked/unchecked
   */
  const handleTableCheckboxChange = (event, row) => {
    if (event.target.checked) {
      setSelectedRowsIds([...selectedRowsIds, row.traceId]);
      if (selectedRowsIds.length + 1 === tableData.length) {
        setSelectAll(true);
      }
    } else {
      setSelectedRowsIds(selectedRowsIds.filter((id) => id !== row.traceId));
      setSelectAll(false);
    }
  };

  /**
   * select / unselect all traces
   */
  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedRowsIds(tableData.map((row) => row.traceId));
    } else {
      setSelectedRowsIds([]);
    }
  };

  /**
   * filters table to show only the unprocessed traces
   */
  /*const handleShowUnprocessedOnlyChange = (event) => {
    setShowUnprocessedOnly(event.target.checked);
    if (event.target.checked) {
      setTableData(
        facilityDetails.Traces.filter(
          (row) => row.startTime > latestMapCreateTime
        )
      );
    } else {
      setTableData(facilityDetails.Traces);
    }
    setSelectedRowsIds(
      selectedRowsIds.filter((id) =>
        tableData.some((row) => row.traceId === id)
      )
    );
  };*/

  /**
   * hides delete button and shows delete conf
   */
  const handleDeleteClick = (traceId) => {
    setDeleteConfirmationRow(traceId);
  };

  /**
   *
   */
  const handleCancelDelete = () => {
    setDeleteConfirmationRow(null);
  };

  /**
   * calls api to delete trace and then makes another call to update table.
   */
  const handleConfirmDelete = async (traceId) => {
    try {
      const res = await ApiService.deleteTrace(traceId);
      if (res.status === 204) {
        setAlertMsg("Trace has successfully been deleted !");
        setAlertSeverity("success");
        await getFacilityInfo(facilityDetails.General.facilityId); // trigger data refresh from details card
        setTableData(facilityDetails.Traces); // Update table data with the latest facility details
      } else {
        console.error(`Unexpected status code: ${res.status}`);
        setAlertMsg(`Unexpected status code: ${res.status}`);
        setAlertSeverity("error");
      }
    } catch (error) {
      setAlertMsg("Trace deletion failed !");
      setAlertSeverity("error");
      console.error("Error delete trace", error);
      //setDeleteConfirmationRow(null);
    } finally {
      setDeleteConfirmationRow(null);
    }
  };

  return (
    <TableContainer className="rtable-main-container" component={Paper}>
      <CustomSnackbarAlert
        severity={alertSeverity}
        duration={2500}
        message={alertMsg}
        onAlertClose={() => setAlertMsg("")}
      ></CustomSnackbarAlert>

      {/*<div className="rtable-control-checkbox-1">
        <Checkbox
          checked={showUnprocessedOnly}
          onChange={(event) => handleShowUnprocessedOnlyChange(event)}
          size="small"
          sx={{
            color: "rgb(58, 71, 100)",
            "&.Mui-checked": {
              color: "rgb(58, 71, 100)",
            },
          }}
        />
        <span className="rtable-text-1">Show unprocessed only</span>
      </div>*/}

      <div className="rtable-control-checkbox-2">
        <Checkbox
          checked={selectAll}
          onChange={(event) => handleSelectAllChange(event)}
          size="small"
          sx={{
            color: "rgb(58, 71, 100)",
            "&.Mui-checked": {
              color: "rgb(58, 71, 100)",
            },
          }}
        />
        <span className="rtable-text-1">Select all traces</span>
      </div>
      <Table
        className="rtable-table"
        stickyHeader
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">Select</TableCell>
            {/*<TableCell align="center">Processed</TableCell>*/}
            <TableCell align="center">Created</TableCell>
            <TableCell align="center">Size (kB)</TableCell>
            <TableCell align="center">Car Model</TableCell>
            <TableCell align="center">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {facilityDetails.Traces && facilityDetails.Traces.length > 0 && (
          tableData.map((row) => (
            <TableRow
              key={row.traceId}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="center" component="th" scope="row">
                <Checkbox
                  onChange={(event) => handleTableCheckboxChange(event, row)}
                  checked={selectedRowsIds.includes(row.traceId)}
                  sx={{
                    color: "rgb(58, 71, 100)",
                    "&.Mui-checked": {
                      color: "rgb(58, 71, 100)",
                    },
                  }}
                />
              </TableCell>
              {/*<TableCell align="center">
                {row.startTime < latestMapCreateTime ? (
                  <TaskAltIcon className="rtable-icon-1" />
                ) : (
                  <ErrorOutlineIcon className="rtable-icon-2" />
                )}
              </TableCell>*/}
              <TableCell align="center">
                {convertUnixTimestamp(row.startTime)}
              </TableCell>
              <TableCell align="center">{(row.fileSize/1000).toFixed(2)}</TableCell>
              <TableCell align="center">{row.vehicleModel}</TableCell>
              <TableCell
                sx={{ width: "120px" }}
                align="center"
                component="th"
                scope="row"
              >
                {deleteConfirmationRow === row.traceId ? (
                  <div className="rtable-delete-confirmation-container">
                    <span>Delete trace?</span>
                    <div className="rtable-button-container">
                      <button
                        className="rtable-button-yes"
                        onClick={() => handleConfirmDelete(row.traceId)}
                        size="small"
                      >
                        <span className="rtable-btn-text">Yes</span>
                      </button>
                      <button
                        className="rtable-button-no"
                        onClick={handleCancelDelete}
                        size="small"
                      >
                        <span className="rtable-btn-text">No</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <IconButton
                    className="mtable-3d-iconbtn"
                    onClick={() => handleDeleteClick(row.traceId)}
                    aria-label="delete"
                  >
                    <DeleteForeverIcon className="rtable-icon-3" />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))
        )}
        </TableBody>
      </Table>

      <Button
        className="rtable-create-button"
        //disabled={loading}
        variant="contained"
        disableElevation
        onClick={handleCreateMap}
      >
        <span className="rtable-create-button-bottom-text">Create Map</span>
        {/*{loading === false && (
          <span className="rtable-create-button-bottom-text">Create Map</span>
        )}
        {loading && (
          <span
            className={
              loading
                ? "rtable-create-button-bottom-text animate"
                : "rtable-create-button-bottom-text"
            }
          >
            Map being created...
          </span>
        )}*/}
      </Button>
    </TableContainer>
  );
};

export default TracesTable;
