import React, { createContext, useReducer } from 'react';
import globalReducer from './globalReducer';

// Initial state
const initialState = {
  incompleteJobs: [
   // { jobId: '65651', jobStatus: 'PENDING', facilityId: 'xxxxx' },
   // { jobId: '65621', jobStatus: 'RUNNING', facilityId: 'xxxxx' }, 
  ],
  lastCreatedMap: null,

};

const GlobalContext = createContext(initialState);



// Provider component
const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalProvider };
