const globalReducer = (state, action) => {
  switch (action.type) {
    case "ADD_JOB":
      return {
        ...state,
        incompleteJobs: [...state.incompleteJobs, action.payload],
      };
    case "UPDATE_JOB_STATUS":
      return {
        ...state,
        incompleteJobs: state.incompleteJobs.map((job) =>
          job.jobId === action.payload.jobId
            ? { ...job, jobStatus: action.payload.jobStatus }
            : job
        ),
      };
    case "REMOVE_JOB":
      return {
        ...state,
        incompleteJobs: state.incompleteJobs.filter(
          (job) => job.jobId !== action.payload
        ),
      };
    case "SET_LAST_CREATED_MAP_TIME": 
      return {
        ...state,
        lastCreatedMap: action.payload,
      };
    default:
      return state;
  }
};

// to use :
/* import React, { useContext } from 'react';
import { JobContext } from '../context/JobContext';

  const { state, dispatch } = useContext(JobContext);
  const { incompleteJobs } = state;

  const addJob = () => {
    const newJob = { jobId: '65631', jobStatus: 'PENDING' };
    dispatch({ type: 'ADD_JOB', payload: newJob });
  };

  const updateJobStatus = (jobId, status) => {
    dispatch({ type: 'UPDATE_JOB_STATUS', payload: { jobId, jobStatus: status } });
  };

  const removeJob = (jobId) => {
    dispatch({ type: 'REMOVE_JOB', payload: jobId });
  };*/

export default globalReducer;
